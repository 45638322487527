@import url('https://fonts.googleapis.com/css?family=Kalam:400,700|Comfortaa|Raleway|Special+Elite|Yeon+Sung|Roboto:300,400,900|Shadows+Into+Light|Amatic+SC:700|Pacifico&display=swap');
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
body {
  background-color: rgb(22, 22, 22);
  font-size: 70%;
  height: 100%;
  margin: 1%;
}
html{
  height: 100%;
}

.container{
  margin: 0 auto;
  position: relative;
}

.vbox{
  display: flex;
  flex-direction: column;
}
.margin_lr{
  margin-left: 2vw;
  margin-right: 2vw;
}
.s_btn{
  overflow: hidden;
  border: none;
  text-align: center;
  text-decoration: none;
  background-color: rgba(0,0,0,0);
  padding: 0;
}
.margin_top_10{
  margin-top: 10vh;
}
.margin_top_7{
  margin-top: 7vh;
}
.margin_top_3{
  margin-top: 3vh;
}
.center{
  text-align: center;
}
.full_height{
  height: 88vh;
}
.full_height_p{
  height: 100%;
}


.menu_btn{
  font-family: "Raleway";
  font-size: 1.5em;
  background-color: rgb(127,127,127);
  color: white;
  padding: 1vh 0 1vh 0;
  margin-top: 2.5vh;
  border-radius: 1vh;
  margin-bottom: 2.5vh;
  box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.5);
}
.back_btn{
  position: absolute;
  top:0;
  left: 0;
  font-size: 3em;
  color: white;
}
#main_logo{
  margin-top: 10vh;
  margin-bottom: 15vh;
  text-align: center;
  overflow: hidden;
  font-family: 'Kalam', cursive;
  font-variant: small-caps;
  text-decoration: none;
  font-size: 5em;
  color: white;
  letter-spacing: 5px;
  text-shadow: 0px -7px 7px rgb(0,0,0);
}
.form_label{
  font-family: "Raleway";
  font-size: 1.2em;
  margin-right: 5mm;
  color: white;
  margin-bottom: 1vh;
  text-align: center;
}
.form_input{
  font-family: "Raleway";
  font-size: 1.2em;
  margin-bottom: 3vh;
  border-radius: 4px;
  text-decoration: none;
  border: none;
  padding: 6px;
  box-shadow: 0px 6px 7px 0px rgba(0, 0, 0, 0.5);
}
.form_input:focus {
  box-shadow: 0px 0px 13px 4px rgba(255, 255, 255, 0.5);
}

.text0{
  text-align: center;
  overflow: hidden;
  font-family: 'Comfortaa';
  text-decoration: none;
  font-size: 2em;
  color: white;
  margin-bottom: 1.5vh;
}
.text1{
  text-align: center;
  overflow: hidden;
  font-family: 'Comfortaa';
  text-decoration: none;
  font-size: 5em;
  font-family: 'Special Elite';
  color: #ceffe4;
  margin-bottom: 1vh;
}
.text2{
  text-align: center;
  overflow: hidden;
  font-family: 'Comfortaa';
  text-decoration: none;
  font-size: 1.2em;
  color: white;
  margin-bottom: 6vh;
}

.name_list{
  overflow: auto;
  width: inherit;
  text-align: center;
  font-family: 'Comfortaa';
  text-decoration: none;
  font-size: 1.4em;
  color: white;
  padding: 5px;
  max-height: 30vh;
  border: 2px solid #888888;
  border-radius: 3px;
}
.name_list_item{
  margin-top: 1vh;
  margin-bottom: 1vh;
}

/* game play (gp -> game play)*/

.two_dots{
  position: relative;
  top: -2px;
  color:white;
  font-family: 'Roboto', sans-serif;
  font-size: 2.4em;
  display: inline;
}
.gp_value_0{
  color:white;
  font-family: 'Roboto', sans-serif;
  font-size: 2.2em;
  margin-left: 5%;
  display: inline;
}
#star_box{
  position: absolute;
  left: 20%;
  top: 1%
}
#lives_box{
  position: absolute;
  left: 65%;
  top: 1%
}
#gp_star_ico{
  color:gold;
  font-size: 1.9em;
  display: inline;
}
#gp_lives_ico{
  color:red;
  font-size: 1.9em;
  display: inline;
}

.gp_top_box{
  position: absolute;
  top: 6.5%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}
.gp_level_indc{
  color: #9cffa4;
  font-family: 'Roboto', sans-serif;
  font-size: 2em;
}
.gp_use_star_btn{
  font-family: "Raleway";
  font-size: 1.5em;
  background-color: rgb(10, 88, 0);
  color: white;
  padding: 1% 0 1% 0;
  margin-top: 2%;
  border-radius: 1vh;
  margin-bottom: 10%;
  box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: inherit;
  max-width: 75%;
}
.gp_use_star_btn:disabled{
  background-color: rgb(155, 155, 155);
}

.gp_last_played_card_box{
  position: absolute;
  top: 23.5%;
  left: 17.5%;
  width: 65%;
  height: 50%;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  border: 2px solid #585858;
  border-radius: 3px;
  box-shadow: 0px 0px 20px 12px #1d1d1d;
}

.gp_lpc_no{ /* lpc -> last played card*/
  font-family: 'Amatic SC', cursive;
  color: white;
  font-size: 10em;
  text-shadow: 5px 6px black;
}

.gp_lpc_player_name{ /* lpc -> last played card*/
  font-family: "Raleway";
  font-size: 1em;
  color: white;
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
}

.gp_bottom_box{
  position: absolute;
  bottom: 2%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}

.gp_play_btn{
  font-family: "Raleway";
  font-size: 1.5em;
  background-color: rgb(12, 41, 0);
  color: white;
  padding: 3% 0 3% 0;
  margin-top: 2%;
  border-radius: 1vh;
  margin-bottom: 8%;
  box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: inherit;
  max-width: 85%;
}
.gp_play_btn:disabled{
  background-color: rgb(155, 155, 155);
}
.gp_remaining_nums_list{
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  color: white;
  padding: 1% 0 1% 0;
  margin-top: 1%;
  margin-bottom: 1%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: inherit;
}

.notif_box{
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0, 0.8);
}

.notif_message_box{
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  color: white;
  position: absolute;
  top: 30%;
  max-height: 30%;
  width: 95%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 10% 0 10% 0;
  overflow: auto;
  border-radius: 1vh;
  border-width: 0.2vmax;
  border-style: groove;
  border-color: white;
}
.notif_message_box::first-line{
  font-weight: bold;
  font-size: 1.5em;
}

.notif_button{
  font-family: "Raleway";
  font-size: 1.5em;
  background-color: rgb(74, 74, 74);
  color: white;
  padding: 3% 0 3% 0;
  border-radius: 1vh;
  border-color: #969696;
  border-style: solid;
  border-width: 0.1vmax;
  margin-top: 2%;
  margin-bottom: 8%;
  overflow: hidden;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 80%;
  max-height: 30%;
  width: 95%;
}
/*           */

.gm_quit_btn{ /*game menu quit button*/
  font-family: "Raleway";
  font-size: 1.5em;
  background-color: rgb(120, 0, 0);
  color: white;
  padding: 1% 0 1% 0;
  margin-top: 2%;
  border-radius: 1vh;
  margin-bottom: 10%;
  box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: inherit;
}

.about_discription{
  color: rgb(255, 255, 255);
  font-family: 'Roboto', sans-serif;
  font-size: 1.2em;
  text-align: justify;
  overflow: auto;
  max-height: 80vh;
  margin-top: 15%;
}

@media only screen and (min-width: 768px) {
  .container{
     width: 660px;
  }
  body {
    font-size: 1vmax;
    height: 100%;
    margin: 1%;
    overflow: hidden;
  }
  .margin_lr{
    margin-left: 0;
    margin-right: 0;
  }
}